import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Observable } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({
    'authorization':String(localStorage.getItem('authorization'))
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  restUrl: String = '';
  constructor(private http: HttpClient) {
    this.restUrl = "http://localhost:3000/api/"
  }
  post(url: any, params: any,data:any): Observable<any> {
    httpOptions.headers.set('authorization', (localStorage.getItem('authorization') || ''));
    return this.http.post(this.restUrl + url, params, httpOptions);
    
  }
  get(url: any,data:any): Observable<any> {
    httpOptions.headers.set('authorization', (localStorage.getItem('authorization') || ''));
    return this.http.get(this.restUrl + url, httpOptions);
  }

  filePost(url: any, params: any): Observable<any> {
    httpOptions.headers.set('authorization', (localStorage.getItem('authorization') || ''));
    return this.http.post(this.restUrl + url, params, {
      headers: new HttpHeaders({
        'authorization': 'Basic YWRtaW46MTIzNDU2'
      }), responseType: 'blob'
    });
  }

  private loggedInStatus = JSON.parse(localStorage.getItem('isLogin') || ('false'));

  get LoginStatus() {
    return JSON.parse(localStorage.getItem('isLogin') ||
      this.loggedInStatus.toString());
  }

}
