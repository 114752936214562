import { Routes } from '@angular/router';
import { ComponentsComponent } from 'src/app/components/components.component';
import { AuthGuard } from 'src/app/tool/service/auth.guard';
import { LoginGuard } from 'src/app/tool/service/login.guard';

export const CommonLayout_ROUTES: Routes = [

    //Dashboard
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate:[AuthGuard]
    },

     //Component
     {
        path: '',
        component: ComponentsComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('../../components/components.module').then(m => m.ComponentsModule),
                canActivate:[AuthGuard]
            }
        ],
    },
];