import { SideNavInterface } from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
    {
        path: '/dashboard/default',
        title: 'Dashboard',
        iconType: 'fontawesome',
        iconTheme: 'outline',
        icon: 'fa-solid fa-gauge',
        submenu: []
    },
    {
        path: '/userlist/all',
        title: 'Users',
        iconType: 'fontawesome',
        iconTheme: 'outline',
        icon: 'fa-solid fa-users',
        submenu: [
        ]
    },
    {
        path: '/trxlsit/all',
        title: 'Transaction list',
        iconType: 'fontawesome',
        iconTheme: 'outline',
        icon: 'fa-solid fa-money-bill-transfer',
        submenu: [
        ]
    },
    {
        path: '/withlist/all',
        title: 'Withdrawal List',
        iconType: 'fontawesome',
        iconTheme: 'outline',
        icon: 'fa-solid fa-business-time',
        submenu: [
        ]
    },
    {
        path: 'announcement',
        title: 'Announcements List',
        iconType: 'fontawesome',
        iconTheme: 'outline',
        icon: 'fa-solid fa-bullhorn',
        submenu: [
        ]
    },
    {
        path: '/news',
        title: 'News List',
        iconType: 'fontawesome',
        iconTheme: 'outline',
        icon: 'fa-solid fa-scroll',
        submenu: [
        ]
    },
    {
        path: '/learn',
        title: 'Learn List',
        iconType: 'fontawesome',
        iconTheme: 'outline',
        icon: 'fa-solid fa-laptop-file',
        submenu: [
        ]
    }
]    